/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

:root {
    --original-gray-background-color: #E0E3E6;
    --selected-background-color: #f0f3f6;

    --ion-background-color: var(--selected-background-color);
    --ion-text-color: #303133;
    --ion-font-family: 'Rubik', sans-serif;

    --sqc-gap-spacer: 25vh;

    --sqc-red: #E6232A;

    --usn-gold: #F7B512;

    @media (prefers-color-scheme: light) {
        --corebg: #fff;
        --smartphone-color: rgb(25, 26, 25);
    }
    @media (prefers-color-scheme: dark) {
        --corebg: #000;
        --smartphone-color: rgb(223, 223, 237);
    }
    @mixin mdH {
        @media (min-height: 1096px) {
            @content
        }
    }

    h1,
    h2,
    h3 {
        font-family: 'Rubik', sans-serif;
        font-weight: 500;
    }

    ion-title {
        font-family: 'Rubik', sans-serif;
        font-weight: 500;
        --ion-text-color: #303133;

        .left {
            width: 50%;
            float: left;
        }

        .right {
            width: 50%;
            float: right;
        }
    }

    /* .header-block {
        margin-top: var(--sqc-gap-spacer);
        margin-bottom: var(--sqc-gap-spacer);
        text-align: center;
        h1 {
            font-size: 2.5em;
        }
        width: 100%;
        text-align: center;
        -moz-transition: opacity ease-in-out;
        -webkit-transition: opacity ease-in-out;
        transition: opacity ease-in-out;
    } */
    --minimum-height: 1096px;

    --smartphone-ratio: 2;
    --smartphone-width: 290px;
    --smartphone-height: calc(var(--smartphone-width) * var(--smartphone-ratio));
    --smartphone-border-width: calc(var(--smartphone-width) / 22.5);
    --smartphone-bottom-height: 15px;
    --smartphone-top-height: var(--smartphone-bottom-height);
    --smartphone-border-radius: 26px;
    --smartphone-margin: var(--smartphone-bottom-height);
    // --smartphone-color: rgb(25, 26, 25);

    --storyboard-padding: 10px;
    --storyboard-a-height: calc(100vh - var(--smartphone-height) / 2 - var(--smartphone-margin) - var(--storyboard-padding) - 56px);
    --storyboard-a-header-height: 350px;

    --storyboard-b-height: calc(100vh - 350px - 56px);
    --storyboard-bottom-margin: calc(var(--storyboard-b-height) - var(--smartphone-height) - var(--smartphone-margin) * 2 - var(--storyboard-padding) * 2); 

    @include mdH {
        --storyboard-a-height: calc(var(--minimum-height) - var(--smartphone-height) / 2 - var(--smartphone-margin) - var(--storyboard-padding) - 56px);
        --storyboard-b-height: calc(var(--minimum-height) - 350px - 56px);
    }
    @media ( min-height: 1096px )
{
    --storyboard-a-height: calc(var(--minimum-height) - var(--smartphone-height) / 2 - var(--smartphone-margin) - var(--storyboard-padding) - 56px);
    --storyboard-b-height: calc(var(--minimum-height) - 350px - 56px);
}
    /* .sb {
        position: relative;
        height: var(--storyboard-b-height);
        overflow-y: scroll;
    } */
    ion-toolbar {
        --background: rgba(0,0,0,0);
    }
    /* .text-grid {
        max-width: 80rem;
        // position: relative;
        padding: 3rem;
        left: 50%;
        transform: translateX(-50%);
    } */
    .text-banner {
        max-width: 250px;
    }
    
    /* .p8-banner {
        height: 100%;
        object-fit: cover;
    } */
    /* .textPara {
        max-width: 600px;
    } */
    .navButton {
        -moz-transition: all 0.25s linear;
        -webkit-transition: all 0.25s linear;
        transition: all 0.25s linear;
        border-bottom: solid 1px rgba(0,0,0,0);
    }
    .navButton:hover {
        border-bottom: solid 1px var(--ion-text-color);
    }

    .storyboard {
        margin-bottom: var(--storyboard-bottom-margin);
    }

    .sb-a {
        border: solid 1px rgba(0,0,0,0);
        position: relative;
        height: var(--storyboard-a-height);
        min-height: 500px;
        overflow: hidden;
        // top:33%;

        .header-block {
            -moz-transition: opacity ease-in-out;
            -webkit-transition: opacity ease-in-out;
            transition: opacity ease-in-out;

            text-align: center;
            position: relative;
            height: var(--storyboard-a-header-height);
            margin-top: calc((var(--storyboard-a-height) / 2) - 56px);

            h1 {
                font-size: 2.5em;
            }
        }
    }

    .sb-b {
        // border: solid 1px #0f0;
        padding: var(--storyboard-padding);
        position: relative;
        height: var(--storyboard-b-height);
        min-height: 800px;
        //bottom: 0;
        //transform: translateY(50%);
        margin-bottom: var(--storyboard-bottom-margin);
        
        .smartphone-container {
            -moz-transition: transform 1s ease-in-out;
            -webkit-transition: transform 1s ease-in-out;
            transition: transform 1s ease-in-out;

            // border: solid 1px #f00;
            width: calc(var(--smartphone-width) + var(--smartphone-margin) * 2);
            margin-right: auto;
            margin-left: auto;
        }
    }
    ion-icon.sb-large {
        font-size: 5em;
    }
    ion-icon.sb-med {
        font-size: 4.2em;
    }

    .sb-generic {
        // border: solid 1px #00f;
        padding: var(--storyboard-padding);
        position: relative;
        text-align: center;
        // height: var(--storyboard-b-height);
        min-height: calc((100vh - 56px) / 3);
        margin-bottom: var(--storyboard-bottom-margin);
        margin-top: var(--storyboard-bottom-margin);
        h1 {
            font-size: 2.5em;
        }
        ion-row {
            ion-col{
                text-align: center;
                ion-icon{
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .sb-image {
        padding-top: var(--storyboard-padding);
        padding-bottom: var(--storyboard-padding);
        position: relative;
        text-align: center;
        min-height: calc((100vh - 56px) / 3);
        margin-bottom: var(--storyboard-bottom-margin);
        margin-top: var(--storyboard-bottom-margin);
        h1 {
            font-size: 2.5em;
        }
        ion-grid{
            // max-width: 89rem;
            // left: 50%;
            // transform: translateX(-50%);
        }
        ion-row {
            margin-right: 3rem;
            margin-top: 3rem;
            
            ion-col{
                text-align: center;
                padding-left: 3rem;
            }
            /* ion-col.text {
                max-width: 650px;
            } */
            ion-col.text-bubble {
                margin: 1rem;
                padding: 1rem;
                // background: #D8E5EF;
                border-radius: 5px;
                text-align: justify;
                text-transform: uppercase;
            }
        }
    }

    .footer {
        height: 56px;
        width: 100%;
        padding: var(--storyboard-padding);
    }

    .smartphone {
        position: relative;
        width: calc(var(--smartphone-width) + (var(--smartphone-border-width)*2));
        height: calc(var(--smartphone-height) + var(--smartphone-top-height) + var(--smartphone-bottom-height));
        margin-right: auto;
        margin-left: auto;
        border: var(--smartphone-border-width) var(--smartphone-color) solid;
        border-top-width: var(--smartphone-top-height);
        border-bottom-width: var(--smartphone-bottom-height);
        border-radius: var(--smartphone-border-radius);
        margin-top: var(--smartphone-margin);
        margin-bottom: var(--smartphone-margin);
        box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.15);
    }

    .smartphone:before {
        content: '';
        display: block;
        // width: calc(var(--smartphone-width) / 2.5);
        // height: 35px;
        width: calc(var(--smartphone-width) / 2);
        height: 40px;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--smartphone-color);
        border-radius: 0px 0px 10px 10px;
        opacity: 1;
        z-index: 10;
    }

    
    /* The screen (or content) of the device */
    .smartphone .content {
        width: var(--smartphone-width);
        height: var(--smartphone-height);
        overflow-y: hidden;
        overflow-x: hidden;
        border-radius: calc(var(--smartphone-border-radius) - var(--smartphone-border-width));
        // opacity: 1;
        // border-radius: 5px 5px calc(var(--smartphone-border-radius) - var(--smartphone-border-width)) calc(var(--smartphone-border-radius) - var(--smartphone-border-width));
        // lt rt rb lb
        /* img.start-img {
            width: calc(var(--smartphone-width) / 2);
            position: absolute;
            top: 36%;
            left: 25%;
        } */
        img {
            -moz-transition: opacity 0.5s ease-in-out;
            -webkit-transition: opacity 0.5s ease-in-out;
            transition: opacity 0.5s ease-in-out;
        }
    }
    /* .tmpImgPos {
        transform: translateY(calc(var(--smartphone-height) * -1 - 4px));
    } */
    #ph1 {
        transform: translateY(calc(var(--smartphone-height) * -1 - 4px));
    }
    #ph2 {
        transform: translateY(calc(var(--smartphone-height) * -2 - 8px));
    }
    #ph3 {
        transform: translateY(calc(var(--smartphone-height) * -3 - 12px));
    }
    #ph4 {
        transform: translateY(calc(var(--smartphone-height) * -4 - 16px));
    }
    img.hideImg {
        opacity: 0;
    }
    img.showImg {
        opacity: 1;
    }

    .side-content-a {
        -moz-transition: all 0.5s linear;
        -webkit-transition: all 0.5s linear;
        transition: all 0.5s linear;

        position: absolute;
        opacity: 0;
        width: 50%;
        right: 10%;
        top: 0;
        height: 100%;
        
        visibility: hidden;

    }
    .loading {
        -moz-transition: opacity 1s .5s linear;
        -webkit-transition: opacity 1s .5s linear;
        transition: opacity 1s .5s linear;
    }
    .internal-box {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        ion-row{
            padding-top: 30px;
            padding-bottom: 30px;
            -moz-transition: background-color 0.25s ease-in-out;
            -webkit-transition: background-color 0.25s ease-in-out;
            transition: background-color 0.25s ease-in-out;
            &:hover {
                // background-color: #dfe5eb;
                @media (prefers-color-scheme: light) {
                    background-color: #dfe5eb;
                }
                @media (prefers-color-scheme: dark) {
                    background-color: #202121;
                }
            }
        }
    }
    
}